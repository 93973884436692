import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/lieky/olynth_ha_05/" className="sel">
              {" "}
              &gt; OLYNTH® HA 0,05%{" "}
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="product_content">
                <div className="product_name">
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> HA 0,05%{" "}<br />nosová roztoková aerodisperzia
                    </p>
                    <div className="desc">
                    RÝCHLA A ÚČINNÁ ÚĽAVA OD PRÍZNAKOV NÁDCHY U DETÍ OD 2 DO 6 ROKOV.
                    ZVLHČUJE NOSOVÚ SLIZNICU VĎAKA TROM ZVLHČUJÚCIM LÁTKAM
                    </div>
                  </div>
                  <div className="photo">
                    <img src="/web/kcfinder/uploads/files/produkty/sk/4.png" />
                  </div>
                </div>
                <div className="wysiwyg">
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <div>
                      <strong>
                        Citlivý detský noštek vyžaduje počas nádchy viac
                        starostlivosti. Pretože upchatý nos a vysušená
                        nosová sliznica uľahčujú rýchle šírenie infekcie v
                        organizme<sup>1</sup>, je veľmi dôležité predchádzať
                        výskytu možných komplikácií spôsobených nádchou.
                      </strong>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      OLYNTH<sup>® </sup>HA 0,05% je liek vo forme nosovej
                      roztokovej aerodisperzie určený:
                    </div>
                    <ul>
                      <li>
                        na zmiernenie prekrvenia a opuchu a zvlhčenie nosovej
                        sliznice pri akútnej, vazomotorickej a alergickej nádche
                      </li>
                      <li>
                        na urýchlenie uvoľnenia sekrécie pri zápale prinosových
                        dutín (paranazálna sinusitída) a pri katare Eustachovej
                        (sluchovej) trubice spojenom s nádchou
                      </li>
                    </ul>
                    <div>&nbsp;</div>
                    <div>
                      <strong>Hlavné výhody</strong>
                    </div>
                    <ul>
                      <li>
                        rýchlo uvoľňuje upchatý nos a vedľajšie nosové dutiny,
                        nástup účinku sa obvykle dostaví o 5 – 10 minút
                      </li>
                      <li>
                        vytvára jemný rozprašok, a preto je aplikácia šetrná aj
                        pre malé deti
                      </li>
                      <li>
                        neobsahuje konzervačné látky, môže sa používať 1 rok po
                        otvorení
                      </li>
                      <li>zvlhčuje nosovú sliznicu vďaka trom zvlhčujúcim látkam (kyselina hyalurónová*,
                         glycerol a sorbitol)
                      </li>
                    </ul>
                    <div>&nbsp;</div>
                    <div>
                      <strong>Dávkovanie a spôsob podávania</strong>
                    </div>
                    <div>Liek je určený na liečbu detí od 2 do 6 rokov.</div>
                    <div>
                      Aplikuje sa podľa potreby, najviac však 3x denne 1 dávka
                      do každej nosovej dierky.
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <strong>Spôsob podania</strong>
                    </div>
                    <div>
                      Odstráňte ochranný kryt. Pred prvým použitím niekoľkokrát
                      stlačte pumpičku, až dosiahnete vystrekovanie súvislej
                      dávky aerosólu. Nosová roztoková aerodisperzia je takto
                      pripravená na okamžité použitie. Pri podávaní si
                      vstreknite iba jednu dávku do každej nosovej dierky. Pri
                      vstrekovaní dávky ľahko dýchajte príslušnou nosovou
                      dierkou. Dávkovaciu liekovku držte zvisle. Nevstrekujte
                      dávku držaním liekovky vo vodorovnej polohe alebo dnom
                      nahor. Po použití nasaďte späť na pumpičku ochranný kryt.
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <strong>Upozornenie</strong>
                    </div>
                    <div>
                      <span>
                        Liek OLYNTH<sup>®</sup> HA 0,05% sa nemá používať dlhšie
                        ako 7 dní, pokiaľ nie je stanovené lekárom inak.
                        Aplikáciu je možné opakovať len po tom, čo liečba bola
                        na niekoľko dní prerušená.
                      </span>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <span>
                        Z hygienických dôvodov sa liek nemá po otvorení používať
                        dlhšie ako 12 mesiacov.
                      </span>
                    </div>
                    <div>&nbsp;</div>
                  </div>
                  <p>
                    <sup>
                      <span
                        style={{
                          "font-size": "9.166666030883789px"
                        }}
                      >
                        1&nbsp;
                      </span>
                    </sup>
                    <span
                      style={{
                        "font-size": "11px"
                      }}
                    >
                      Jaki wpływ na odporność ma Higiena Nosa ?, Redakcja
                      abcZdrowie.pl, 22-06-2015,
                      https://portal.abczdrowie.pl/jaki-wplyw-ma-higiena-nosa-na-odpornosc&nbsp;
                      <br />
                      <br />* Vo forme sodnej soli.
                    </span>
                  </p>
                  <p>
                    <br />
                    <a
                      className="btn_link"
                      href="http://www.sukl.sk/buxus/generate_page.php?page_id=386&lie_id=36096"
                      target="_blank"
                    >
                      PIL - Písomná informácia pre používateľa (link na
                      oficiálne webové stránky ŠÚKL){" "}
                    </a>
                  </p>
                  <p>&nbsp;</p>
                </div>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Zistiť viac</p>
                <div className="line">
                  <a href="/radca/nadcha/ako_si_spravne_cistit_nos/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/jak_prawidlowo_oczyszczac_nos.png)"}} />
                    </div>
                    <div className="c2">
                      Ako si správne čistiť nos pri nádche?{" "}
                    </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/radca/nadcha/spoznajte_typy_nadchy/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/artykuly/poznaj_rodzaje_kataru.png)"}} />
                    </div>
                    <div className="c2">Spoznajte typy nádchy </div>
                  </a>
                </div>
              </div>
              <a href="/upravte_liek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/sk/Test_SK.png" />
              </a>
              <a href="/lieky/olynth_ha_05/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
